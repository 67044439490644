<template>
  <v-container class="pa-6" fluid>
    <v-row justify="center" class="mb-0">
      <v-col cols="12" class="d-flex flex-column" md="9">
        <v-skeleton-loader
          type="card"
          :loading="dataLoading"
          class="flex d-flex flex-column"
        >
          <v-card class="flex d-flex flex-column dashboard-card px-4">
            <v-card-title>
              <v-spacer></v-spacer>
              <span class="body-2" style="color: #88a6c9"
                ><v-icon small color="#88a6c9" class="pr-1"
                  >mdi-calendar</v-icon
                >
                Inscrit le
                {{
                  user_data.profile.created.toDate().toLocaleDateString()
                }}</span
              >
            </v-card-title>

            <v-container>
            <v-row class="my-0">
              <v-col cols="12" md="6">
                <v-card-title class="font-weight-medium pt-4">
                 👋 Bonjour {{ user_data.profile.name.split(" ")[0] }}
                </v-card-title>
                <v-card-text class="lighten--text text-justify">
                  Vous avez réalisé
                  <span class="blue--text text--accent-4 font-weight-black"
                    >{{ Math.round(averageProgress) }}%</span
                  >
                  de votre formation ! Continuez votre apprentissage en suivant
                  vos modules e-learning et vos séances d'accompagnement avec
                  votre formateur {{ coach.firstName }} !
                </v-card-text>
              </v-col>
              <v-col cols="12" md="6" align-self="end">
                <apexchart
                  type="radialBar"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </v-col>
            </v-row>
            </v-container>
          </v-card>
        </v-skeleton-loader>
      </v-col>

      <v-col cols="12" md="3" class="d-flex flex-column">
        <v-skeleton-loader
          type="card"
          :loading="dataLoading"
          class="flex d-flex flex-column"
        >
          <v-card flat class="flex d-flex flex-column dashboard-card">
            <v-toolbar color="transparent" flat height="30" class="pt-3">
              <v-toolbar-items>
                <v-chip
                label
                color="#E9EDFE"
                text-color="blue accent-4"
                class="font-weight-medium"
              >
                Mon formateur
              </v-chip>
              </v-toolbar-items>
            </v-toolbar>
            
            <v-row justify="center" class="mb-0 pt-4">
              <v-avatar size="80" style="border:3px solid black;">
                <v-img :src="coach.photo"></v-img>
              </v-avatar>
            </v-row>
            <v-row justify="center" class="my-0 py-0 font-weight-medium">
              {{ coach.firstName + " " + coach.name }}
            </v-row>

            <v-row justify="center">
                 <v-tooltip bottom max-width="260" color="#000" v-if="showCancellMeetingBtn" >
                      <template v-slot:activator="{ on, attrs }">
                        <div v-on="on" class="d-inline-block" v-bind="attrs">
                          <v-chip small color="success" outlined label><v-icon left small>mdi-calendar-check</v-icon>{{formatDateTime(getNextEventDate)}}</v-chip>                        </div>
                      </template>
                  <span class="caption">Votre prochain rendez-vous a été planifié le {{ formatDateTime(getNextEvent.createdAt) }}</span>
                  </v-tooltip>

                  <v-tooltip bottom max-width="260" color="#000" v-if="showRescheduleMeetingBtn" >
                      <template v-slot:activator="{ on, attrs }">
                        <div v-on="on" class="d-inline-block" v-bind="attrs">
                          <v-chip small color="error" outlined label><v-icon left small>mdi-calendar-alert</v-icon>{{formatDateTime(getNextEventDate)}}</v-chip>                        </div>
                      </template>
                  <span class="caption">Votre prochain rendez-vous a été annulé le {{ formatDateTime(getNextEvent.cancelledAt) }}</span>
                  </v-tooltip>
            </v-row>
            <v-row justify="end" class="py-1 mb-0 pr-4">
              <v-btn
                color="blue accent-4"
                text
                small
                :href="coach.agenda"
                target="_blank"
                v-if="showBookMeetingBtn"
                >Prendre rendez-vous
                <v-icon right>mdi-chevron-right</v-icon></v-btn
              >

              <v-btn
                color="blue accent-4"
                text
                small
                :href="coach.agenda"
                target="_blank"
                v-if="showRescheduleMeetingBtn"
                >Replanifier un rdv
                <v-icon right>mdi-chevron-right</v-icon></v-btn
              >

              <v-btn
                color="error"
                text
                small
                href="mailto:support@webmyday.io?subject=Annulation%20de%20seance"
                target="_blank"
                v-if="showCancellMeetingBtn"
                >Annuler mon rdv
                <v-icon right>mdi-chevron-right</v-icon></v-btn
              >

              <div v-else-if="hasNoCoachingLeft && currentSession.id != '9JynXXNiyqKJbacC6CF3' && currentSession.id != '8xlUA5MhfuBQCFWnYhNB'">
                <v-tooltip bottom color="#000" max-width="280">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      left
                      small
                      v-on="on"
                      v-bind="attrs"
                      class="mr-0"
                      color="#88a6c9"
                      >mdi-help-circle</v-icon
                    >
                  </template>
                  <p
                    style="text-align: justify; font-size: 13px"
                    class="pa-2 mb-0"
                  >
                    Vous n'avez plus de séances de formation disponible. Cependant
                    vous pouvez réserver une séance supplémentaire si besoin
                    avec votre formateur en cliquant sur ce bouton !
                  </p>
                </v-tooltip>

                <v-btn color="blue accent-4" text small :href="coach.agenda_premium"
                target="_blank">
                  Séance supplémentaire
                  <v-icon right>mdi-chevron-right</v-icon></v-btn
                >
              </div>
               <div v-else-if="currentSession.id === '9JynXXNiyqKJbacC6CF3' || currentSession.id === '8xlUA5MhfuBQCFWnYhNB'">
                <v-tooltip left color="#000" max-width="280">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      left
                      small
                      v-on="on"
                      v-bind="attrs"
                      class="mr-0"
                      color="#88a6c9"
                      >mdi-help-circle</v-icon
                    >
                  </template>
                  <p
                    style="text-align: justify; font-size: 13px"
                    class="pa-2 mb-0"
                  >
                    Votre session de formation est terminée depuis plus d'un an. Veuillez contacter l'équipe pédagogique si besoin par ticket ou à support@webmyday.io
                  </p>
                </v-tooltip>

                <v-btn color="blue accent-4" text small disabled>
                  Prendre rendez-vous
                  <v-icon right>mdi-chevron-right</v-icon></v-btn
                >
              </div>
            </v-row>
          </v-card>
        </v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row justify="center" class="mb-0 mt-0">
      <v-col cols="12" md="6" class="d-flex flex-column">
        <v-skeleton-loader
          type="card"
          :loading="dataLoading"
          class="flex d-flex flex-column"
        >
          <v-card class="flex d-flex flex-column dashboard-card pa-4">
            <v-row align="center" class="mb-0 pl-4" justify="start">
              <v-col cols="6" md="3" align-self="center">
                <v-avatar color="#e9edfe" size="65">
                  <v-icon color="blue accent-4">mdi-chart-line-variant</v-icon>
                </v-avatar>
              </v-col>

              <v-col>
                <v-card-subtitle class="pb-0 text-body-2">
                  Temps de formation suivi
                </v-card-subtitle>
                <v-card-title class="title font-weight-bold py-0">{{
                  learningDone
                }}</v-card-title></v-col
              >
            </v-row>
          </v-card>
        </v-skeleton-loader>
      </v-col>

      <v-col cols="12" md="6" class="d-flex flex-column">
        <v-skeleton-loader
          type="card"
          :loading="dataLoading"
          class="flex d-flex flex-column"
        >
          <v-card class="flex d-flex flex-column dashboard-card pa-4">
            <v-row align="center" class="mb-0 pl-4" justify="start">
              <v-col cols="6" md="3" align-self="center">
                <v-avatar color="rgb(5, 245, 7, 0.25)" size="65">
                  <v-icon color="green accent-4">mdi-laptop-account</v-icon>
                </v-avatar>
              </v-col>

              <v-col>
                <v-card-subtitle class="pb-0 text-body-2">
                  Séances réalisées
                </v-card-subtitle>
                <v-card-title class="title font-weight-bold py-0"
                  >{{ coachingsDone.length }}/{{ maxCoachings }}</v-card-title
                ></v-col
              >
            </v-row>
          </v-card>
        </v-skeleton-loader>
      </v-col>
      <!-- <v-col cols="12" md="4" class="d-flex flex-column">
        <v-skeleton-loader
          type="card"
          :loading="dataLoading"
          class="flex d-flex flex-column"
        >
          <v-card class="flex d-flex flex-column dashboard-card pa-4">
            <v-row align="center" class="mb-0 pl-4" justify="start">
              <v-col cols="6" md="3" align-self="center">
                <v-avatar color="#fff1a4" size="65">
                  <v-icon color="orange accent-4">mdi-message-badge</v-icon>
                </v-avatar>
              </v-col>

              <v-col>
                <v-card-subtitle class="pb-0 text-body-2">
                  Tickets ouverts
                </v-card-subtitle>
                <v-card-title class="title font-weight-bold py-0">{{
                  tickets.length
                }}</v-card-title></v-col
              >
            </v-row>
          </v-card>
        </v-skeleton-loader>
      </v-col> -->
    </v-row>

    <v-row justify="center" class="mb-0">
      <v-col cols="12" md="6" class="d-flex flex-column">
        <v-skeleton-loader
          type="card"
          :loading="dataLoading"
          class="flex d-flex flex-column"
        >
          <v-card class="flex d-flex flex-column dashboard-card pa-4">
            <v-card-title class="text-body-1 font-weight-bold"
              >Module en cours
              <v-spacer></v-spacer>
              <v-btn
                text
                x-small
                color="blue accent-4"
                :to="{
                  name: 'academie',
                  params: { course: $route.params.course },
                }"
                >Voir tout <v-icon right>mdi-chevron-right</v-icon></v-btn
              >
            </v-card-title>
            <v-card-text class="pt-2">
              <v-row justify="center" class="mb-0 pb-0" v-if="currentModule">
                <!-- <v-col cols="4" align-self="start">
                  <v-img :src="currentModule.pic" max-width="180"></v-img>
                </v-col> -->
                <v-col class="px-4">
                  <v-row class="mb-1">
                    <v-chip
                      label
                      color="#E9EDFE"
                      text-color="blue accent-4"
                      class="font-weight-medium"
                    >
                      Module {{ currentModule.index }}
                    </v-chip>
                  </v-row>
                  <v-row>
                    <span class="font-weight-medium black--text body-2">{{
                      currentModule.title
                    }}</span>
                  </v-row>
                  <v-row class="text-justify lighten--text caption">
                    <v-col cols="4" class="px-0" align-self="center">
                      <v-hover v-slot="{ hover }">
                        <v-img :src="currentModule.pic" class="rounded">
                          <v-fade-transition>
                            <v-overlay v-if="hover" absolute>
                              <v-btn
                                fab
                                small
                                color="blue accent-4"
                                @click="resumeModule(currentModule.index - 1)"
                                ><v-icon>mdi-play</v-icon></v-btn
                              >
                            </v-overlay>
                          </v-fade-transition>
                        </v-img>
                      </v-hover>
                    </v-col>
                    <v-col align-self="center">{{
                      currentModule.desc.slice(0, 200) + " ..."
                    }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" align-self="center" class="px-0">
                      <v-progress-linear
                        :value="
                          Math.round(moduleProgress(currentModule.index - 1))
                        "
                        color="blue accent-4"
                        rounded
                        :height="6"
                      ></v-progress-linear>
                    </v-col>
                    <v-col
                      cols="4"
                      class="caption"
                      style="color: #88a6c9"
                      align-self="center"
                    >
                      {{ Math.round(moduleProgress(currentModule.index - 1)) }}%
                      visionné
                    </v-col>
                    <v-col style="text-align: right" align-self="center">
                      <v-btn
                        text
                        small
                        color="blue accent-4"
                        @click="resumeModule(currentModule.index - 1)"
                        >Reprendre
                        <v-icon right>mdi-chevron-right</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="6" class="d-flex flex-column">
        <v-skeleton-loader
          type="card"
          :loading="dataLoading"
          class="flex d-flex flex-column"
        >
          <v-card
            class="flex d-flex flex-column dashboard-card pa-4"
            min-height="340"
          >
            <v-card-title class="text-body-1 font-weight-bold">
              <v-badge
                color="error"
                :content="tasksToDo.length + tasksDoing.length"
                :value="tasksToDo.length + tasksDoing.length"
              >
                Tâches à réaliser
              </v-badge>
              <v-spacer></v-spacer>
              <v-btn
                text
                x-small
                color="blue accent-4"
                :to="{
                  name: 'project-management',
                  params: { course: $route.params.course },
                }"
                >Voir tout <v-icon right>mdi-chevron-right</v-icon></v-btn
              >
            </v-card-title>
            <v-tabs color="blue accent-4" hide-slider right>
              <v-tab class="task-tab">A faire</v-tab>
              <v-tab class="task-tab">En cours</v-tab>
              <v-tab class="task-tab">Fait</v-tab>
              <v-tab-item>
                <v-list
                  dense
                  flat
                  style="max-height: 220px; overflow: auto"
                  v-if="tasksToDo.length > 0"
                >
                  <v-list-item
                    v-for="(task, index) in tasksToDo"
                    :key="index"
                    style="max-height: 40px"
                  >
                    <v-list-item-icon class="mr-2">
                      <v-icon color="error" small>mdi-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-regular">
                        {{ task.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-card-text v-else class="caption">
                  Vous n'avez aucune tâche à réaliser pour le moment !
                </v-card-text>
              </v-tab-item>
              <v-tab-item>
                <v-list
                  dense
                  flat
                  style="max-height: 220px; overflow: auto"
                  v-if="tasksDoing.length > 0"
                >
                  <v-list-item
                    v-for="(task, index) in tasksDoing"
                    :key="index"
                    style="max-height: 40px"
                  >
                    <v-list-item-icon class="mr-2">
                      <v-icon color="amber darken-2" small>mdi-circle</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title class="font-weight-regular">
                        {{ task.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-card-text v-else class="caption">
                  Vous n'avez aucune tâche en cours pour le moment !
                </v-card-text>
              </v-tab-item>
              <v-tab-item>
                <v-list
                  dense
                  flat
                  style="max-height: 220px; overflow: auto"
                  v-if="tasksDone.length > 0"
                >
                  <v-list-item
                    v-for="(task, index) in tasksDone"
                    :key="index"
                    style="max-height: 40px"
                  >
                    <v-list-item-icon class="mr-2">
                      <v-icon color="green accent-2" small>mdi-circle</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title class="font-weight-regular">
                        {{ task.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-card-text v-else class="caption">
                  Vous n'avez aucune tâche réalisée pour le moment !
                </v-card-text>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-skeleton-loader>
      </v-col>
    </v-row>

    <!-- New coachings row  -->

    <v-row justify="center">
      <v-col cols="12" md="8" class="d-flex flex-column">
        <v-skeleton-loader
          type="card"
          :loading="dataLoading"
          class="flex d-flex flex-column"
        >
          <v-card class="flex d-flex flex-column dashboard-card pa-4">
            <v-card-title class="text-body-1 font-weight-bold">
              Mes dernières séances
              <v-spacer></v-spacer>

              <span class="body-2" style="color: #88a6c9"
                >{{ coachingsDone.length }}/{{ maxCoachings }}</span
              >
            </v-card-title>

            <vueper-slides
              v-if="coachings.length > 0"
              ref="myVueperSlides"
              class="no-shadow pt-6"
              :visible-slides="3"
              slide-multiple
              :gap="3"
              :slide-ratio="1 / 4"
              :dragging-distance="200"
              :breakpoints="{ 800: { visibleSlides: 2, slideMultiple: 2 } }"
              :arrows="false"
              :touchable="false"
              fixed-height="280px;"
            >
              <vueper-slide
                v-for="(coaching, index) in coachings"
                :key="index"
                :style="{
                  background: 'white',
                  borderRadius: '8px;',
                }"
                class="border-secondary"
              >
                <template #content>
                  <v-card-title>
                    <v-chip
                      label
                      color="#E9EDFE"
                      text-color="blue accent-4"
                      class="font-weight-medium"
                    >
                      Séance n°{{ coaching.id + 1 }}
                    </v-chip>
                    <v-spacer></v-spacer>
                    <v-tooltip
                      bottom
                      color="#000"
                      v-if="
                        coaching.recording &&
                        (coaching.recording.includes('vidyard.com') ||
                          coaching.recording.includes('loom.com') ||
                          coaching.recording.includes('google.com') ||
                          coaching.recording.includes('vimeo.com') ||
                          coaching.recording.includes('tldv.io'))
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          fab
                          color="blue accent-4"
                          small
                          dark
                          depressed
                          :href="coaching.recording"
                          target="_blank"
                        >
                          <v-icon>mdi-play</v-icon>
                        </v-btn>
                      </template>
                      <span class="caption">Voir le replay</span>
                    </v-tooltip>

                    <v-tooltip bottom color="#000" v-else>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-on="on" class="d-inline-block" v-bind="attrs">
                          <v-btn fab small depressed disabled>
                            <v-icon>mdi-play</v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <span class="caption">Replay indisponible</span>
                    </v-tooltip>
                  </v-card-title>
                  <v-card-subtitle class="caption" style="color: #88a6c9"
                    ><v-icon small color="#88a6c9">mdi-calendar</v-icon>
                    {{ coaching.date }}</v-card-subtitle
                  >
                  <v-card-text
                    class="px-1"
                    style="max-height: 130px; overflow: auto"
                  >
                    <v-subheader
                      style="
                        font-size: 12px;
                        font-weight: 700;
                        text-transform: uppercase;
                        height: 38px;
                        color: black;
                      "
                      v-if="coaching.summary.length > 0"
                      >Points abordés</v-subheader
                    >
                    <ul>
                      <li
                        v-for="(li, index) in coaching.summary"
                        :key="index"
                        class="summary"
                      >
                        {{ li }}
                      </li>
                    </ul>
                  </v-card-text>
                </template>
              </vueper-slide>
            </vueper-slides>
            <v-card-text v-else class="caption">
              Vous n'avez encore réalisé aucune séance de formation pour le moment ! Il est
              temps de faire connaissance avec votre formateur
              {{ coach.firstName }} !
            </v-card-text>
            <v-row class="px-4">
              <v-btn
                color="blue accent-4"
                text
                small
                :href="coach.agenda"
                target="_blank"
                v-if="showBookMeetingBtn"
                >Prendre rendez-vous
                <v-icon right>mdi-chevron-right</v-icon></v-btn
              >

              <v-btn
                color="blue accent-4"
                text
                small
                :href="coach.agenda"
                target="_blank"
                v-if="showRescheduleMeetingBtn"
                >Replanifier un rdv
                <v-icon right>mdi-chevron-right</v-icon></v-btn
              >

              <v-btn
                color="error"
                text
                small
                href="mailto:support@webmyday.io?subject=Annulation%20de%20seance"
                target="_blank"
                v-if="showCancellMeetingBtn"
                >Annuler mon rdv
                <v-icon right>mdi-chevron-right</v-icon></v-btn
              >
              <div v-if="hasNoCoachingLeft && currentSession.id != '9JynXXNiyqKJbacC6CF3' && currentSession.id != '8xlUA5MhfuBQCFWnYhNB'">
                <v-tooltip left color="#000" max-width="280">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      left
                      small
                      v-on="on"
                      v-bind="attrs"
                      class="mr-0"
                      color="#88a6c9"
                      >mdi-help-circle</v-icon
                    >
                  </template>
                  <p
                    style="text-align: justify; font-size: 13px"
                    class="pa-2 mb-0"
                  >
                    Vous n'avez plus de séance de formation disponible. Cependant
                    vous pouvez réserver une séance supplémentaire si besoin
                    avec votre formateur en cliquant sur ce bouton !
                  </p>
                </v-tooltip>

                <v-btn color="blue accent-4" text small :href="coach.agenda_premium"
                target="_blank">
                  Séance supplémentaire
                  <v-icon right>mdi-chevron-right</v-icon></v-btn
                >
              </div>
               <div v-if="currentSession.id === '9JynXXNiyqKJbacC6CF3' || currentSession.id === '8xlUA5MhfuBQCFWnYhNB'">
                <v-tooltip left color="#000" max-width="280">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      left
                      small
                      v-on="on"
                      v-bind="attrs"
                      class="mr-0"
                      color="#88a6c9"
                      >mdi-help-circle</v-icon
                    >
                  </template>
                  <p
                    style="text-align: justify; font-size: 13px"
                    class="pa-2 mb-0"
                  >
                    Votre session de formation est terminée depuis plus d'un an. Veuillez contacter l'équipe pédagogique si besoin par ticket ou à support@webmyday.io
                  </p>
                </v-tooltip>

                <v-btn color="blue accent-4" text small disabled>
                  Prendre rendez-vous
                  <v-icon right>mdi-chevron-right</v-icon></v-btn
                >
              </div>
            </v-row>
          </v-card>
        </v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="4" class="d-flex flex-column">
        <v-skeleton-loader
          type="card"
          :loading="dataLoading"
          class="flex d-flex flex-column"
        >
          <v-card class="flex d-flex flex-column dashboard-card pa-4">
            <v-card-title class="text-body-1 font-weight-bold">
              Les derniers cours collectifs
              <v-spacer></v-spacer>
              <v-btn
                text
                x-small
                color="blue accent-4"
                :to="{
                  name: 'webinars',
                  params: { course: $route.params.course },
                }"
                >Voir tout <v-icon right>mdi-chevron-right</v-icon></v-btn
              >
            </v-card-title>

            <v-card-text class="px-0 pt-6">
              <v-row
                v-for="(webinar, index) in recentWebinars"
                :key="index"
                class="mb-4"
              >
                <v-col cols="1">
                  <v-divider vertical></v-divider>
                </v-col>
                <v-col cols="9">
                  <v-row
                    class="pb-0 mb-0 font-weight-medium black--text"
                    style="font-size: 13px"
                    >{{ webinar.title }}</v-row
                  >
                  <v-row class="pt-0 mt-0 caption" style="color: #88a6c9">
                    <!-- <v-col>
                      <v-icon small color="#88a6c9" class="pr-1"
                        >mdi-calendar</v-icon
                      >
                      {{ webinar.date.toDate().toLocaleDateString() }}</v-col
                    > -->
                    <v-col
                      class="caption new-grey--text px-0"
                      align-self="center"
                      cols="6"
                    >
                      <v-avatar size="20">
                        <v-img
                          :src="webinar.animator.pic"
                          v-if="webinar.animator.pic != ''"
                        ></v-img>
                        <v-icon v-else>mdi-account-circle</v-icon>
                      </v-avatar>
                      <span class="pl-2">{{ webinar.animator.name }}</span>
                    </v-col>
                    <v-col class="pl-0 pb-0" align-self="center"
                      ><v-icon left small class="new-grey--text">mdi-calendar</v-icon
                        >{{ webinar.date.toDate().toLocaleDateString() }}</v-col
                    >
                  </v-row>
                </v-col>
                <v-col>
                  <v-tooltip bottom color="#000">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        fab
                        color="blue accent-4"
                        x-small
                        dark
                        depressed
                        :to="{
                          name: 'webinar',
                          params: {
                            course: $route.params.course,
                            id: webinar.id,
                          },
                        }"
                      >
                        <v-icon>mdi-play</v-icon>
                      </v-btn>
                    </template>
                    <span class="caption">Voir le replay</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-skeleton-loader>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" multi-line bottom>
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue accent-4"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
import { db } from "@/firebase/init";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  name: "Dashboard",
  components: { VueperSlides, VueperSlide },
  data() {
    return {
      snackbarText: "",
      snackbar: false,
      checked: true,
      autoPlaying: true,
      internalAutoPlaying: true,
      slides: [
        {
          title: "Slide #1",
          content: "Slide 1 content.",
        },
        {
          title: "Slide #2",
          content: "Slide 2 content.",
        },
      ],
      model: 0,
      barHeight: "18px",
      barMargin: "2px",
      e1: 0,
      dataLoaded: false,
      taskList: 0,
      dataLoading: true,
      headers: [
        {
          text: "Module",
          align: "start",
          sortable: false,
          value: "title",
        },
        {
          text: "Progression",
          align: "start",
          sortable: false,
          value: "progress",
        },
        {
          text: "Actions",
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],

      chartOptions: {
        colors: ["#2862FF"],
        chart: {
          height: 300,
          offsetY: -20,
          type: "radialBar",
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "70%",
            },
            startAngle: -90,
            endAngle: 90,
            track: {
              strokeWidth: "97%",
              background: "#E5EAFF",
              fillOpacity: 0.85,
              margin: 0,
            },
            dataLabels: {
              name: {
                show: true,
                color: "#88A6C9",
                fontSize: "14px",
                fontWeight: "500",
              },
              value: {
                fontSize: "20px",
                fontWeight: "600",
                show: true,
                offsetY: -37,
              },
            },
          },
        },
        labels: ["Mon avancée"],
        stroke: {
          lineCap: "round",
        },
      },
    };
  },
  computed: {
    ...mapState([
      "project",
      "user_data",
      "modules_v2",
      "progressModules",
      "coaches",
      "coach",
      "coachings",
      "tasks",
      "course",
      "webinars",
      "tickets",
      "userCourseInfo",
      "sessions",
      "userCourseDoc"
    ]),
    showBookMeetingBtn: function(){
      if(!this.hasNoCoachingLeft && this.currentSession.id != '9JynXXNiyqKJbacC6CF3' && this.currentSession.id != '8xlUA5MhfuBQCFWnYhNB' && (!this.getNextEvent)){
        return true
      }
      else {
        return false
      }
    },
    showCancellMeetingBtn: function(){
      if(!this.hasNoCoachingLeft && this.currentSession.id != '9JynXXNiyqKJbacC6CF3' && this.currentSession.id != '8xlUA5MhfuBQCFWnYhNB' && this.getNextEvent && ( (this.getNextEvent.status === 'scheduled') || (this.getNextEvent.status === 'cancelled' && this.getNextEvent.isRescheduled === 'True') )){
        return true
      }
      else {
        return false
      }
    },
    showRescheduleMeetingBtn: function(){
      if(!this.hasNoCoachingLeft && this.currentSession.id != '9JynXXNiyqKJbacC6CF3' && this.currentSession.id != '8xlUA5MhfuBQCFWnYhNB' && (this.getNextEvent && this.getNextEvent.status === 'cancelled' && this.getNextEvent.isRescheduled === 'False')){
        return true
      }
      else {
        return false
      }
    },
    hasCancelledMeeting(calendlyEvents) {
           if (!calendlyEvents || calendlyEvents.length === 0) {
            return false;
        }
      const today = new Date().toISOString();
      const nextMeeting = calendlyEvents.find(event => new Date(event.date) > new Date(today));
      
      return nextMeeting && nextMeeting.status == 'cancelled';
    },
    isCancelled(meeting) {
    return meeting.status === 'cancelled';
  },
  hasFutureEvent(calendlyEvents) {
        if (!calendlyEvents || calendlyEvents.length === 0) {
            return false;
        }
        const now = new Date();
        const futureEvents = calendlyEvents.filter(event => {
            if (!event) return false;
            const eventDate = new Date(event.date);
            return event.status == 'scheduled' && eventDate > now;
        });
        return futureEvents.length > 0;
  },
getNextEventDate() {
let calendlyEvents = this.userCourseInfo.calendlyEvents;
  if (!calendlyEvents || calendlyEvents.length === 0) {
    return "-";
  }

  const nextEvent = this.getNextEvent;

  if (nextEvent && nextEvent.date) {
    return nextEvent.date
  } else {
    return "-";
  }
},
getNextEvent() {
  let calendlyEvents = this.userCourseInfo.calendlyEvents;
  if (!calendlyEvents || calendlyEvents.length === 0) {
    return null;
  }

  const now = new Date();

  // Filtrer les événements futurs
  const futureEvents = calendlyEvents.filter(event => new Date(event.date) > now);

  if (futureEvents.length === 0) {
    // Si aucun événement futur, retourner l'événement annulé non reprogrammé
    const cancelledEvent = calendlyEvents.find(event => event.status === 'cancelled' && event.isRescheduled !== "True");
    return cancelledEvent || null;
  }

  // Trier les événements futurs par date
  const sortedEvents = futureEvents.sort((a, b) => new Date(a.date) - new Date(b.date));

  // Vérifier les événements planifiés et annulés (non reprogrammés)
  const nextScheduledEvent = sortedEvents.find(event => event.status === 'scheduled');
  const nextCancelledEvent = sortedEvents.find(event => event.status === 'cancelled' && event.isRescheduled !== "True");

  // Prioriser les événements planifiés
  if (nextScheduledEvent) {
    return nextScheduledEvent;
  }

  // Si aucun événement planifié n'existe, retourner l'événement annulé non reprogrammé
  return nextCancelledEvent || null;
},
    currentSession: function(){
      return this.sessions.find((session) => session.id === this.userCourseInfo.session_id)
    },
    tasksToDo: function () {
      return this.tasks.filter((task) => task.state === 1);
    },
    tasksDone: function () {
      return this.tasks.filter((task) => task.state === 3);
    },
    tasksDoing: function () {
      return this.tasks.filter((task) => task.state === 2);
    },
    lastCoachings: function () {
      return this.coachings
        .slice()
        .sort(
          (b, a) =>
            new Date(
              moment(a.date, "DD-MM-YYYY") -
                new Date(moment(b.date, "DD-MM-YYYY"))
            )
        );
    },
    recentWebinars: function () {
      return this.webinars.slice(0, 4);
    },
    series: function () {
      return [Math.round(this.averageProgress)];
    },
    currentModule: function () {
      let modulesNotWatched = [];
      let length = this.modules_v2.length - 1;

      this.modules_v2.forEach((modul, moduleIndex) => {
        if (this.moduleProgress(moduleIndex) != 100) {
          modulesNotWatched.push(modul);
        }
      });

      if (modulesNotWatched.length === 0) {
        return this.modules_v2[length];
      } else {
        return modulesNotWatched[0];
      }
    },
    currentModuleLessonsLength: function () {
      let length = 0;
      this.currentModule.chapters.forEach((chap) => {
        length += chap.lessons.length;
      });

      return length;
    },
    currentModuleLessonsDone: function () {
      let lessonsDone = [];
      this.progressModules
        .find((modul) => modul.id === this.currentModule.id)
        .chapters.forEach((chap) => {
          chap.lessons.forEach((less) => {
            if (less.done) {
              lessonsDone += less;
            }
          });
        });

      return lessonsDone.length;
    },
    blurBack: function () {
      if (this.user_data.project.selectedPackage === 0) {
        return "blur(8px)";
      } else {
        return "";
      }
    },
    justifiedRow() {
      if (this.user_data.project.selectedPackage != 4) {
        return "center";
      } else {
        return "start";
      }
    },
    averageProgress() {
      // let modules = this.modules_v2;
      // this.user_data.project.selectedPackage < 3 ? modules.pop() : modules;
      let numberOfModules = this.modules_v2.length;

      let averageModulesProgress = 0;

      this.modules_v2.forEach((modul, modulIndex) => {
        averageModulesProgress += this.moduleProgress(modulIndex);
      });

      return averageModulesProgress / numberOfModules;
    },
    templatesNotEmpty: function () {
      return !this.emptyRows;
    },
    calendlyURL: function () {
      if (this.hasNoCoachingLeft) {
        return `"${this.coach.agenda_premium}?hide_gdpr_banner=1"`;
      } else {
        return `"${this.coach.agenda}?hide_gdpr_banner=1"`;
      }
    },
    firstCallDone: function () {
      return this.coachings.find((coaching) => coaching.id === 0).date;
    },
    coachingsDone: function () {
      return this.coachings.filter((coaching) => coaching.done);
    },
    coachingsDoneNumber: function () {
      return this.coachingsDone.length;
    },
    coachingsDoneRate: function () {
      return (this.coachingsDoneNumber / this.maxCoachings) * 100;
    },
    maxCoachings: function () {
      let id = this.userCourseInfo.selectedPackage;
      let extraNum = parseInt(this.userCourseInfo.extraCoachings);

      if (id === 0 || id === 7 || id === 9 || id === 11 || id === 13) {
        return 0 + extraNum;
      } else if (id === 1 || id === 100) {
        return 4 + extraNum;
      } else if (id === 2 || id === 6) {
        return 6 + extraNum;
      } else if (id === 3 || id === 4 || id === 8 || id === 10 || id === 12 || id === 14 || id === 15 || id === 16 || id === 17) {
        return 8 + extraNum;
      } else {
        return 0;
      }
    },
    hasNoCoachingLeft: function () {
      if (this.coachingsDoneNumber >= this.maxCoachings) {
        return true;
      } else {
        return false;
      }
    },
    lastCoachingDone: function () {
      let lastDate = new Date(
        Math.max.apply(
          null,
          this.coachingsDone
            .filter((coaching) => coaching.date && coaching.date != "")
            .map(function (e) {
              return new Date(moment(e.date, "DD-MM-YYYY"));
            })
        )
      );
      return lastDate;
    },
    nextCoaching: function () {
      let lastDate = new Date(
        Math.max.apply(
          null,
          this.coachingsDone
            .filter((coaching) => coaching.date && coaching.date != "")
            .map(function (e) {
              return new Date(moment(new Date(e.nextDate), "DD-MM-YYYY"));
            })
        )
      );
      return lastDate;
    },
    medalText: function () {
      if (Math.round(this.averageProgress) <= 50) {
        return "bronze";
      } else if (
        Math.round(this.averageProgress) > 50 &&
        Math.round(this.averageProgress) < 85
      ) {
        return "argent";
      } else {
        return "or";
      }
    },
    medalColor: function () {
      if (Math.round(this.averageProgress) <= 50) {
        return "#6D4C41";
      } else if (
        Math.round(this.averageProgress) > 50 &&
        Math.round(this.averageProgress) < 85
      ) {
        return "#9E9E9E";
      } else {
        return "#FFB300";
      }
    },
    nextMedalColor: function () {
      if (this.medalText === "bronze") {
        return "#9E9E9E";
      } else if (this.medalText === "argent") {
        return "#FFB300";
      } else {
        return "#FFB300";
      }
    },
    nextMedalValue: function () {
      if (this.medalText === "bronze") {
        return Math.round((Math.round(this.averageProgress) / 51) * 100);
      } else if (this.medalText === "argent") {
        return Math.round((Math.round(this.averageProgress) / 86) * 100);
      } else {
        return Math.round((Math.round(this.averageProgress) / 100) * 100);
      }
    },
    nextMedalInt: function () {
      if (this.medalText === "bronze") {
        return 51 - Math.round(this.averageProgress);
      } else if (this.medalText === "argent") {
        return 86 - Math.round(this.averageProgress);
      } else {
        return 100 - Math.round(this.averageProgress);
      }
    },
    learningDone: function () {
      let pad = function (num, size) {
        num = num.toString();
        while (num.length < size) num = "0" + num;
        return num;
      };
      let time = 0;
      this.modules_v2.forEach((modul, index) => {
        time += (this.moduleProgress(index) / 100) * modul.minutes;
      });

      if (Math.round(time) < 60) {
        return `${Math.round(time)} min`;
      } else {
        let num = Math.round(time);
        let hours = num / 60;
        let rhours = Math.floor(hours);
        let minutes = (hours - rhours) * 60;
        let rminutes = Math.round(minutes);
        return rhours + "h" + pad(rminutes, 2);
      }
    },
  },
  methods: {
    formatDateTime(date) {
    if (!date) {
        return "-";
    }
    date = new Date(date);
    return date.toLocaleString('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,  // Format 24 heures
        timeZone: 'Europe/Paris'  // Spécifie le fuseau horaire si nécessaire
    });
},
    getWebinarIcon(id) {
      if (id === 1) {
        return "mdi-magnify";
      } else if (id === 2) {
        return "mdi-bullhorn";
      } else if (id === 4) {
        return "mdi-code-tags";
      } else if (id === 0) {
        return "mdi-comment-outline";
      }
      else if (id === 3) {
        return "mdi-briefcase";
      }
    },
    updateTasks(id, bool) {
      this.$store
        .dispatch("updateTasks", {
          id: id,
          done: bool,
          course: this.$route.params.course,
        })
        .then(() => {
          if (bool) {
            this.snackbarText =
              "Félicitations ! Vous venez de terminer une tâche de plus 🎉";
            this.snackbar = true;
          } else {
            this.snackbarText =
              "Vous venez de marquer cette tâche comme non terminée, au travail 🤓";
            this.snackbar = true;
          }
        });
    },
    slideTitle(index) {
      return `Coaching n°${index + 1}`;
    },
    createTasksCollection() {
      this.tasks.forEach((task, index) => {
        db.collection(
          `users/${this.user_data.profile.user_id}/courses/${this.$route.params.course}/tasks`
        )
          .doc()
          .set({
            checklist: task.checklist,
            subtitle: task.subtitle,
            title: task.title,
            index: index,
          });
      });
    },
    numberOfDaysAgo(date) {
      if (!date) {
        return null;
      }
      let today = moment();
      let formatedDate = new Date(moment(date, "DD-MM-YYYY"));
      return today.diff(formatedDate, "days");
    },
    dateColor(date) {
      let today = new Date();
      let formatedDate = new Date(moment(date, "DD-MM-YYYY"));
      if (this.coachings.length === 0 || !date) {
        return "grey";
      }
      if (formatedDate <= moment(today).subtract(1, "month")) {
        return "error";
      } else if (formatedDate <= moment(today).subtract(14, "days")) {
        return "#ffd600";
      } else {
        return "blue accent-4";
      }
    },
    barColor(id) {
      if (this.coachingsDone.some((coaching) => coaching.id === id)) {
        return "#2862FF";
      } else {
        return "#C1D1FF";
      }
    },
    barColor_2(id) {
      if (this.coachingsDone.some((coaching) => coaching.id === id)) {
        return "#2862FF";
      } else {
        return "#C1D1FF";
      }
    },
    resumeModule(moduleIndex) {
      let unSeenLessons = [];
      if (
        this.moduleProgress(moduleIndex) === 0 ||
        this.moduleProgress(moduleIndex) === 100
      ) {
        this.$router.push({
          path: `/academie/course/${this.$route.params.course}/${
            moduleIndex + 1
          }/1/1`,
        });
      } else {
        this.modules_v2[moduleIndex].chapters.forEach((chapter, chapIndex) => {
          chapter.lessons.forEach((less, lessIndex) => {
            if (
              !this.progressModules[moduleIndex][`chapter_${chapIndex + 1}`][
                `lesson_${lessIndex + 1}`
              ]
            ) {
              unSeenLessons.push({
                chapPath: chapIndex + 1,
                lessPath: lessIndex + 1,
              });
            }
          });
        });

        this.$router.push({
          path: `/academie/course/${this.$route.params.course}/${
            moduleIndex + 1
          }/${unSeenLessons[0].chapPath}/${unSeenLessons[0].lessPath}`,
        });
      }
    },
    numberOfLessons(modul) {
      let lessons = 0;
      modul.chapters.forEach((chap) => {
        lessons += chap.lessons.length;
      });
      return lessons;
    },
    log(i) {
      console.log(i);
    },
    uncompleteLesson(modul, chap, less) {
      return !this.progressModules[modul][`chapter_${chap + 1}`][
        `lesson_${less + 1}`
      ];
    },
        numberOfLessonsCompleted(modul, chap, lessons) {
      let arr = [];

      lessons.forEach((less, index) => {
        if (this.completedLesson(modul, chap, index)) {
          arr.push(less);
        }
      });

      return arr.length;
    },
      completedLesson: function (modul, chap, less) {
      return this.progressModules[modul][`chapter_${chap + 1}`][
        `lesson_${less + 1}`
      ];
    },
    chapterProgress(modul, chap) {
      if(this.modules_v2.length > 0){

     
      let chapterLength = this.modules_v2[modul].chapters[chap].lessons.length;
      let lessonsCompleted = 0;

      if (this.progressModules[modul]) {
        // Object.keys(this.progressModules[modul][`chapter_${chap + 1}`]).forEach(
        //   (lesson) => {
        //     let lessonIndex = parseInt(lesson.slice(-1)) - 1;

        //     if (!this.uncompleteLesson(modul, chap, lessonIndex)) {
        //       lessonsCompleted++;
        //     }
        //   }
        // );

         lessonsCompleted = this.numberOfLessonsCompleted(modul, chap, this.modules_v2[modul].chapters[chap].lessons)

        return parseFloat((lessonsCompleted / chapterLength) * 100);
      }
       }
    },
    moduleProgress(index) {
      let moduleLength = this.modules_v2[index].chapters.length;
      let averageProgress = 0;

      this.modules_v2[index].chapters.forEach((chapter, chapIndex) => {
        averageProgress += this.chapterProgress(index, chapIndex);
      });

      return averageProgress / moduleLength;
    },
  },
  async mounted() {
    await this.$store.dispatch("setUserData");
    await this.$store.dispatch("setCourse", this.$route.params.course);
    await this.$store.dispatch("setCoaches");
    await this.$store.dispatch("setUserCourseInfo", this.$route.params.course);
    await this.$store.dispatch("setUserCourseDoc", this.$route.params.course);
    await this.$store.dispatch("setCoach", this.userCourseInfo.coach);
    await this.$store.dispatch("setModulesV2", this.$route.params.course);
    await this.$store.dispatch("setProgressModules", this.$route.params.course);
    await this.$store.dispatch("setTasks", this.$route.params.course);
    await this.$store.dispatch("setCoachings", this.$route.params.course);
    await this.$store.dispatch("setWebinars");
    await this.$store.dispatch("setTickets");
        await this.$store.dispatch("setSessions", this.$route.params.course);
    await this.$store.dispatch("updateStaticGlobalProgress", {
      course: this.$route.params.course,
      progress: Math.round(this.averageProgress),
    });
         this.dataLoaded = true;
    setTimeout(() => {
      this.dataLoading = false;
    }, 1400);
  },
};
</script>

<style>
#modules-table > div > table > tbody > tr:hover {
  background: white !important;
}

#modules-table > div > table > tbody > tr {
  border-bottom: 2px solid white !important;
}

.card-hover:hover {
  transform: scale(1.02);
}

.card-hover {
  transition: transform 0.5s ease;
}

.v-tooltip > span {
  font-size: 12px !important;
}

.disabled-card {
  filter: blur(1px);
}

.dashboard-card {
  box-shadow: 0 12px 50px 2px #13507c24 !important;
  border-radius: 8px;
}

.vueperslides__bullet .default {
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  box-shadow: none;
  transition: 0.3s;
}

.vueperslides__bullet--active .default {
  background-color: #2862ff;
}

.vueperslide {
  border-radius: 8px;
}

.vueperslides__arrow {
  color: black;
}

li.summary::before {
  content: "👉";
}

.border-secondary {
  border: 2px solid #dbe9f2 !important;
  transition: border 500ms ease-out !important;
}

.border-secondary:hover {
  border: 2px solid #3c6eff !important;
}

.task-checkbox > .v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 10px !important;
}

.task-tab {
  text-transform: none !important;
  font-size: 13px;
}

.rounded-circle {
  border-radius: 50% !important;
}

.new-grey--text {
  color: #88a6c9 !important;
}
</style>